import React from 'react'
import { Layout, Stack, Main } from '@layout'
import PageTitle from '@components/PageTitle'
import Divider from '@components/Divider'
import Seo from '@widgets/Seo'
import ProductExpanded from '@widgets/ProductExpanded'
import { useBlogProducts } from '@helpers-blog'

const PageProducts = props => {
  const products = useBlogProducts()

  return (
    <Layout {...props}>
      <Seo title='Products' />
      <Divider />
      <Stack effectProps={{ effect: 'fadeInDown' }}>
        <PageTitle
          header='Our Products'
          subheader='Conceived for productivity, designed for beauty. Our apps will never leave you behind.'
        />
      </Stack>
      <Stack>
        <Main>
          {products.map((product, i) => (
            <React.Fragment key={`item-${i}`}>
              <Divider />
              <ProductExpanded author={product} withLink />
            </React.Fragment>
          ))}
        </Main>
      </Stack>
    </Layout>
  )
}

export default PageProducts
