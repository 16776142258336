import React from 'react'
import PropTypes from 'prop-types'
import { GatsbyImage as Img } from 'gatsby-plugin-image'
import { Box, css } from 'theme-ui'
import MemphisPattern from '@components/MemphisPattern'
import getImageVariant from '@components/utils/getImageVariant'

//Base size to keep all layers aligned easier
const bs = x => `${x * 0.35}rem`

const styles = {
  wrapper: {
    position: `relative`,
    zIndex: 2,
    textAlign: `center`,
    mb: bs(3),
  },
  imageWrapper: {
    mx: `auto`,
  }
}

const ProductLogo = ({
                  avatar,
                  size,
                  loading,
                  alt
                }) => {
  const image = avatar && getImageVariant(avatar, size)

  if (!image) return null

  return (
    <Box sx={styles.wrapper}>
      <Box sx={styles.vertical}>
        <Img
          image={image}
          alt={alt}
          loading={loading}
          css={css(styles.imageWrapper)}
          objectFit="contain"
        />
      </Box>
    </Box>
  )
}

export default ProductLogo

ProductLogo.defaultProps = {
  size: 'regular',
  withPattern: false
}

ProductLogo.propTypes = {
  size: PropTypes.oneOf([false, 'small', 'regular']),
  patternStyles: PropTypes.object,
  loading: PropTypes.string,
  alt: PropTypes.string
}
